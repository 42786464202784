function Header() {
    return (
        <div>
            <h2 className="text-4xl font-bold text-base items-start mt-5">
                Gleb Lobanov
            </h2>
            <article className="prose font-semibold mt-3">
                Backend developer, researcher, lecturer
            </article>
            <article className="prose font-semibold">
                of Algorithms and Computer Science
            </article>
        </div>
    )
}

export default Header;