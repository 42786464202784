import './App.css';
import ColorSwitch from "./components/ColorSwitch";
import Header from "./components/Header";
import IconList from "./components/IconList";
import TextIcon from "./components/icons/TextIcon";
import TelegramIcon from "./components/icons/TelegramIcon";
import CvIcon from "./components/icons/CvIcon";
import Avatar from "./components/Avatar";

function App() {
    return (
        <div
            className="App dark:bg-gray-900 dark:text-gray-400 transition duration-700 flex justify-center flex-wrap align-middle"
            style={{flexDirection: 'column'}}>
            <ColorSwitch/>
            <div className="p-8 rounded-lg max-w-prose mx-auto">
                <div className="flex flex-wrap align-middle items-center justify-center text-center mx-auto">
                    <Avatar/>
                </div>
                <div className="flex flex-wrap align-middle items-center justify-center text-center mx-auto">
                    <Header/>
                </div>

                <div className="flex flex-wrap align-middle items-center justify-center text-center mx-auto">
                    <div className={"mt-5"}>
                        <IconList>
                            <TextIcon href={"/docs/Gleb Lobanov.pdf"}>
                                <div
                                    className={"bg-blue-500 transition duration-700 dark:bg-green-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-3xl"}>
                                    <CvIcon className={"inline"}/> CV
                                </div>
                            </TextIcon>
                            <TextIcon href={"//t.me/glebodin"}>
                                <div
                                    className={"bg-blue-500 transition duration-700 dark:bg-green-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-3xl"}>
                                    <TelegramIcon className={"inline"} href={"//t.me/glebodin"}/> Telegram
                                </div>
                            </TextIcon>
                        </IconList>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default App;
